@import "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300";

.container-3 {
  /*background: #8499b1;*/
  /*background: #6a7d94;*/
  /*background: linear-gradient(to bottom right, #47596d 40%, #6a7d94 100%);*/
  background: linear-gradient(to bottom left, #1787bb 40%, #0987ad 100%);
  /*background: #776b44;*/
  /*background: linear-gradient(to bottom left, #8499b1 40%, #6a7d94 100%);*/
}

/*
 BUTTON STYLING FOR INTERPRETER BTN
*/

.btn-cust {
  position: relative;
  color: white;
  width: 200px;
  height: 64px;
  line-height: 64px;
  transition: all 0.3s;
  span {
    transition: all 0.3s;
    tranform: scale(1, 1);
  }
}

.btn-cust::before,
.btn-cust::after {
  content: "";
  position: absolute;
  transition: all 0.3s;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* BTN THREE */
.btn-three::before {
  opacity: 0;
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1, 0.1);
}

.btn-three:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}

.btn-three::after {
  transition: all 0.3s;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.btn-three:hover::after {
  transform: scale(1, 0.1);
  opacity: 0;
}

@media only screen and (max-width: 334px) {
  .btn-cust {
    width: 158px;
    height: 64px !important;
  }
}

/*
 BUTTON STYLING FOR SWITCHING LANGUAGE
*/

.button5 {
  display: inline-block;
  padding: 0.4em 1.2em;
  /*padding: 1.1em 2.6em;*/
  border: 0.1em solid #000000;
  margin: 0 0.2em 0.2em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #000000;
  text-shadow: 0 0.04em 0.04em rgba(0, 0, 0, 0.35);
  text-align: center;
  transition: all 0.15s;
  background-color: rgb(196, 149, 22);
}
.button5:hover {
  text-shadow: 0 0 2em rgba(255, 255, 255, 1);
  color: #ebe4e4;
  border-color: #ebe4e4;
}
@media all and (max-width: 30em) {
   .button5 {
    display: block;
    margin: 0.4em auto;
  }
}

@media only screen and (max-width: 334px) {
  .button5 {
    padding: 0.3em 0.05em;
  }
}
